<template>
  <div class="terminal-height">
    <portal to="header">
      <terminal-header
          :title="$t('Acceptance') + ': ' + $route.params.id"
          :out-focused="isFocused"
          @barcode="handleBarcode"
      />
    </portal>

    <portal to="settings">
      <tiles :schema="tilesSchema"/>
    </portal>

    <div class="row items-center border-bottom border-bottom--bold q-px-sm q-py-xs text-grey-7">
      <div>
        <h6 class="q-ma-none text-subtitle2 text-weight-bold">
          {{ $t('PO') + ' #' + $route.params.id }}
        </h6>

        <p v-if="acceptance && acceptance._embedded.warehouse" class="text-caption text-weight-bold q-ma-none">
          {{ acceptance._embedded.warehouse.name }}
        </p>
      </div>

      <q-space/>

      <q-badge
          v-if="acceptance"
          :color="stateColors[acceptance.state]"
          :label="$t(acceptance.state)"
          class="q-py-xs text-capitalize"
      />
    </div>

    <q-carousel
        :model-value="slide"
        transition-prev="scale"
        transition-next="scale"
        swipeable
        animated
        class="terminal-height"
        :keep-alive="true"
    >
      <q-carousel-slide name="scan" class="fit q-pa-none">
        <div class="relative fit">
          <div v-if="acceptance && acceptance.state === 'new' && !place && isEnabledMessage">
            <div
                class="text-center q-pa-sm"
                style="height: 190px;"
            >
              <img
                  src="assets/terminal/terminal11-medium.png"
                  style="width: auto; height: 100%; object-fit: contain;"
              >
            </div>

            <div class="text-center text-subtitle2 q-mb-sm">
              {{ $t(text) }}
            </div>

            <div v-if="isEnabledMessage" class="row items-center justify-center">
              <q-btn
                  :label="$t('Add items')"
                  color="light-blue-9"
                  class="q-mr-md"
                  no-caps
                  @click="disableMessage"
              />

              <q-btn
                  :label="$t('Check items')"
                  color="warning"
                  no-caps
                  @click="checking"
              />
            </div>
          </div>

          <div v-else-if="acceptance && !place">
            <div
                class="text-center q-pa-sm"
                style="height: 190px;"
            >
              <img
                  src="assets/terminal-icons-md/IMG-0946.png"
                  style="width: auto; height: 100%; object-fit: contain;"
              >
            </div>

            <div class="row justify-center">
              <h6 class="q-my-none q-mr-sm">
                {{ $t('Please scan') }}
              </h6>

              <q-badge
                  :label="$t('Basket')"
                  color="amber"
                  class="text-h6"
              />
            </div>
          </div>

          <div v-else-if="acceptance && place && !product">
            <div
                class="text-center q-pa-sm"
                style="height: 190px;"
            >
              <img
                  src="assets/terminal-icons-md/IMG-0946.png"
                  style="width: auto; height: 100%; object-fit: contain;"
              >
            </div>

            <div class="row justify-center">
              <h6 class="q-my-none q-mr-sm">
                {{ $t('Please scan') }}
              </h6>

              <q-badge
                  :label="$t('Items')"
                  color="amber"
                  class="text-h6"
              />
            </div>
          </div>

          <div
              v-if="product"
              :key="`${product.id}:${product.sku}`"
          >
            <product-section :data="product"/>

            <quantity-section :data="quantity" :options="quantityOptions" @click="handleEvent"/>

            <counter-section :data="counter" @click="handleEvent"/>
          </div>

          <div v-if="!isEnabledMessage" class="border-top q-pa-xs q-mt-sm border-top--bold"
               style="max-width: calc(100vw - 16px);">
            <base-place-section
                :data="place"
                :options="placeOptions"
            />
          </div>
        </div>
      </q-carousel-slide>

      <q-carousel-slide name="finish" class="q-pa-none row items-center justify-center terminal-min-height">
        <div>
          <div
              class="text-center q-pa-sm"
              style="height: 190px;"
          >
            <img
                src="assets/terminal-icons-md/IMG-0951.png"
                style="width: auto; height: 100%; object-fit: contain;"
            >
          </div>

          <h6 class="q-my-none text-center">
            {{ $t(finishText) }}
          </h6>
        </div>
      </q-carousel-slide>
    </q-carousel>

    <error-screen
        :message="error"
        @reset="handleErrorClose"
    />

    <offers-search-modal ref="offersSearchModal"/>

    <confirm-modal ref="confirmModal"/>

    <acceptance-items-modal ref="acceptanceItemsModal"/>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Utils
import _ from 'lodash'

export default {
  name: 'AcceptanceProcessBulk',
  data () {
    return {
      isBoxAccepting: false,
      isFocused: false,
      acceptance: null,
      products: [],
      product: null,
      amount: 1,
      place: null,
      lastBarcode: '',
      slide: 'scan',
      error: '',
      isEnabledMessage: true,
      text: 'What needs to be done with the PO?',
      stateColors: {
        new: 'info',
        confirmed: 'yellow',
        complete: 'success',
        checking: 'warning',
        deleted: 'danger',
        preset: 'warning',
        archived: 'dark'
      },
      messages: [
        'You did it!',
        'Great job!',
        'Well done!'
      ],
      finishText: ''
    }
  },
  computed: {
    ...mapGetters([
      'findStoragePlaceEvent'
    ]),
    quantityOptions () {
      console.debug(this.product)
      return {
        isZero: false,
        maxDescription: 'tap to add all',
        max: 'Expected',
        maxCount: this.product.quantityExpected,
        current: 'Scanned'
      }
    },
    placeOptions () {
      return {
        label: 'BASKET',
        defaultColor: 'amber',
        selectedColor: 'positive'
      }
    },
    quantity () {
      if (this.acceptance && this.acceptance.state === 'new') {
        return {
          barcode: this.lastBarcode,
          max: this.product.count,
          disabledMaxClick: true,
          current: this.product.count
        }
      }

      return {
        barcode: this.lastBarcode,
        max: this.product.expected,
        current: this.product.count
      }
    },
    counter () {
      const counterObj = {
        max: 250,
        current: 0
      }

      return counterObj
    },
    tilesSchema () {
      return [
        {
          id: 1,
          label: this.$t('Checking'),
          icon: 'list',
          disabled: this.acceptance && this.acceptance.state !== 'new',
          value: true,
          onChanged: () => {
            this.checking()
          }
        },
        {
          id: 2,
          label: this.$t('Confirmed'),
          icon: 'check',
          value: true,
          onChanged: () => {
            this.confirmed()
          }
        },
        {
          id: 3,
          label: this.$t('The PO products'),
          icon: 'add_shopping_cart',
          value: true,
          onChanged: () => {
            const data = {
              search: '',
              document: this.$route.params.id,
              place: this.place,
              noSelect: true
            }

            this.onFocus()

            return this.$refs.acceptanceItemsModal.show(data)
                .finally(() => {
                  this.onFocusOut()
                })
          }
        }
      ]
    }
  },
  mounted () {
    this.$service.acceptance.find(this.$route.params.id)
        .then(item => {
          this.acceptance = item

          if (this.acceptance.state === 'checking') {
            this.text = 'The PO is in the Checking status now! Please scan a Basket barcode for check-in!'
            this.isEnabledMessage = false
          }
        })
  },
  unmounted () {
    this.deleteAllHelpers()

    if (this.place) {
      this.$channels.user.publish('closePlace', this.place.id)
    }
  },
  methods: {
    ...mapMutations([
      'deleteAllHelpers',
      'addErrorNotification',
      'addSountNotification'
    ]),
    ...mapActions([
      'addHelper'
    ]),
    handleEvent (value) {
      if (!value.event) {
        return
      }

      const events = {
        'counter.submit': (data) => {
          this.amount = Number(data.value || 0)
          return this.handleBarcode({ raw: this.lastBarcode, value: this.lastBarcode, type: '' })
        },
        'counter.focus': () => {
          this.isFocused = true
        },
        'counter.focusOut': () => {
          this.isFocused = false
        }
      }

      if (typeof events[value.event] === 'function') {
        return events[value.event](value.data)
      }

      console.error(`Event is not recognized! ${value.event}`)
    },
    disableMessage () {
      this.isEnabledMessage = false
      this.text = 'Please scan a Basket barcode'
      this.addSountNotification()
    },
    handleErrorClose () {
      this.error = ''
    },
    onFocus () {
      this.isFocused = true
    },
    onFocusOut () {
      this.isFocused = false
    },
    handleBarcode (barcode) {
      if (barcode.type !== 'S/P' && !this.place) {
        this.addErrorNotification({ message: 'You must scan working place!' })
        return
      }

      if (barcode.type === 'S/P') {
        return this.scanPlace(barcode)
      }

      return this.scanItem(barcode)
    },
    scanPlace (barcode) {
      if (this.findStoragePlaceEvent(barcode.value)) {
        this.addErrorNotification({ message: 'Someone other work on that place!' })
        return
      }

      if (this.place && this.place.id === barcode.value) {
        this.add({ message: 'You already scan this place!' })
        return
      }

      return this.$service.storagePlace.get(barcode.value)
          .then(place => {
            if (place.type !== 'employee') {
              this.addErrorNotification({ message: 'Place must be type employee!' })
              return
            }

            if (this.place) {
              this.$channels.user.publish('closePlace', this.place.id)
            }

            this.place = place
            this.$channels.user.publish('openPlace', place.id, place)

            this.addSountNotification()
            this.deleteAllHelpers()
          })
    },
    save (product) {
      if (product.id) {
        const data = {
          count: (product.count || 0) + this.amount
        }

        return this.$service.acceptanceItem.save(data, product.id, this.acceptance.state)
      }

      const data = {
        document: this.$route.params.id,
        productOffer: { id: product._embedded.productOffer.id, shop: product._embedded.shop.id },
        count: this.amount,
        sku: product.sku || undefined,
        shop: product._embedded.shop.id
      }

      if (this.acceptance.state === 'checking') {
        data.expected = 0
      }

      return this.$service.acceptanceItem.save(data, undefined, this.acceptance.state)
      // .catch(err => {
      //   const devLog = {
      //     environment: {
      //       class: this.$entities.Orderadmin_Storage_Entity_Movement_Acceptance
      //     },
      //     result: {
      //       message: err.message,
      //       data,
      //       meta: {
      //         url: this.$service.acceptance._url
      //       }
      //     }
      //   }

      //   this.$service.devLog.save(devLog).then(log => {
      //     this.error = `Format of received data is incorect, please make a screenshot and send it to support - Log id: ${log.id}`
      //   })
      // })
    },
    scanItem (barcode) {
      return this.findProduct(barcode)
          .then(product => {
            if (!product) {
              this.addErrorNotification({ message: 'Product with that barcode is not found!' })
              return null
            }

            return this.save(product)
                .then((updatedProduct) => {
                  this.product = updatedProduct

                  let isExist = false

                  this.products = this.products.map(x => {
                    if (x.id === updatedProduct.id) {
                      isExist = true
                      return { ...updatedProduct }
                    }

                    return x
                  })

                  if (!isExist) {
                    this.products = [{ ...updatedProduct }, ...this.products]
                  }

                  this.amount = 1
                  this.lastBarcode = updatedProduct.sku

                  this.addSountNotification()
                  return { ...updatedProduct }
                })
                .catch(err => {
                  this.error = `Format of received data is incorect, please make a screenshot and send it to support - Place: ${this.place && this.place.id} - ${err.message}`
                })
          })
    },
    findProduct (barcode) {
      const product = this.products.find(x => (x.barcode || []).includes(barcode.raw) || x.sku === barcode.raw)

      if (product) {
        return Promise.resolve(product)
      }

      return this.findAcceptanceItem(barcode.raw)
          .then(item => {
            if (item) {
              return { ...item, sku: barcode.raw }
            }

            return this.findOffer(barcode)
                .then(offer => {
                  if (!offer) {
                    return null
                  }

                  return {
                    ...offer,
                    id: undefined,
                    count: 0,
                    expected: 0,
                    sku: barcode.raw,
                    _embedded: {
                      ...offer._embedded,
                      productOffer: { ...offer }
                    }
                  }
                })
          })
    },
    findItemAsExistingOne (product) {
      const query = {
        per_page: 5,
        page: 1,
        filter: [
          { type: 'eq', field: 'document', value: this.$route.params.id },
          { type: 'eq', field: 'productOffer', value: product.id }
        ]
      }

      return this.$service.acceptanceItem.getAll(query, this.acceptance.state, this.place.id)
    },
    manualOfferSearch ({ search, description, defaultState }) {
      return this.$refs.confirmModal.show({ description, hasClose: false })
          .then(isOk => {
            return isOk
                ? this.$refs.offersSearchModal.show({ search, defaultState })
                : null
          })
    },
    findAcceptanceItem (value) {
      const query = {
        per_page: 25,
        page: 1,
        filter: [
          { type: 'eq', field: 'document', value: this.$route.params.id }
        ]
      }

      if (value.startsWith('S/I/PO') && !value.startsWith('S/I/POI')) {
        const lastIndex = value.endsWith('*')
            ? value.length - 1
            : value.length

        let str = value.slice(6, lastIndex)
        query.filter.push({ type: 'eq', field: 'productOffer', value: str })
      } else {
        query.filter.push({ type: 'eq', field: 'sku', value })
      }

      return this.$service.acceptanceItem.getAll(query, this.acceptance.state, this.place.id)
          .then(({ items, page, totalPages, totalItems }) => {
            if (!items[0]) {
              return null
            }

            if (items.length === 1) {
              return items[0]
            }

            const data = {
              search: value,
              document: this.$route.params.id,
              place: this.place,
              title: 'We found more than one product with that barcode, please select one!',
              defaultState: { items, page, totalPages, totalItems }
            }

            this.onFocus()

            return this.$refs.acceptanceItemsModal.show(data)
                .finally(() => {
                  this.onFocusOut()
                })
          })
    },
    findOffer (barcode) {
      const query = {
        per_page: 25,
        page: 1,
        search: barcode.raw,
        filter: []
      }

      return this.$service.offer.getAll(query)
          .then(({ items, page, totalPages, totalItems }) => {
            if (!items[0]) {
              return this.manualOfferSearch({ description: this.$t('The product card with this barcode wasn\'t found. Do you want to add this barcode to the product card?') })
            }

            if (items.length === 1) {
              return items[0]
            }

            return this.manualOfferSearch({
              search: barcode.raw,
              description: this.$t('We found more than one product, do you want to select it?'),
              defaultState: { items, page, totalPages, totalItems }
            })
          })
    },
    checking () {
      return this.$service.acceptance.save({ state: 'checking' }, this.$route.params.id)
          .then(acceptance => {
            this.isEnabledMessage = false
            this.acceptance = acceptance
            this.product = null
            this.amount = 1
            this.products = this.products.map(product => ({ ...product, expected: product.count, count: 0 }))

            this.deleteAllHelpers()
            this.text = 'The PO is in the Checking status now! Please scan a Basket barcode for check-in!'
            this.addSountNotification()
          })
    },
    confirmed () {
      const description = this.acceptance.state === 'checking'
          ? this.$t('Are you sure that you want to confirmed acceptance? There are expected items that are not scanned. Expected items will be removed after confirmed.')
          : this.$t('Are you sure that you want to confirmed acceptance? There are expected items that are not scanned. All of them will become in state new after confirmed.')

      const expectedItem = this.products.find(item => (item.expected || 0) > 0)

      return Promise.resolve(!!expectedItem)
          .then(hasExpectedItems => {
            return hasExpectedItems
                ? this.$refs.confirmModal.show({ description })
                : true
          })
          .then(isOk => {
            if (!isOk) {
              return
            }

            this.$service.acceptance.save({ state: 'confirmed' }, this.$route.params.id)
                .then(() => {
                  this.slide = 'finish'
                  this.finishText = _.sample(this.messages)

                  this.timeout = setTimeout(() => {
                    this.handleBack()
                  }, 3000)
                })
          })
    },
    handleBack () {
      this.$router.push('/terminal/acceptances')
    }
  }
}
</script>
